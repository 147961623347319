<template>
  <div class="topbanner" :style="{'backgroundColor': comObj.style.backgroundColor}" @click="jump">
    <swiper :options="swiperOption" ref="nowSwiper" style="height:160px;border-radius:10px">
      <!-- slides -->
      <swiper-slide
        class="swiper-no-swiping"
        v-for="(item, idx) in comObj.data"
        :key="idx"
        :style="[comObj.style, { backgroundImage: 'url(' + item.backgroungImage +')'}]"
      ></swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="banwater"></div>
    </swiper>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { mapGetters } from "vuex";
export default {
  props: ["comObj", "nowComIdx"],
  name: "Swiper",
  components: {
    swiper,
    swiperSlide
  },
  computed: {
    ...mapGetters(["myswiper", "initSwiper"]),
    swiper() {
      return this.$refs.nowSwiper.swiper;
    }
  },
  data() {
    return {
      swiperOption: {
        effect: "slide",
        grabCursor: true,
        simulateTouch: false, // 拒绝接受鼠标点击事件
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        pagination: {
          el: ".swiper-pagination"
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        onClick(swiper) {
          console.log(swiper);
        }
      }
    };
  },
  watch: {
  },
  created() {
    console.log(this.comObj);
  },
  mounted() {
    // 关闭抓手形状
    this.$refs.nowSwiper.swiper.unsetGrabCursor();
  },
  methods: {
    jump() {
      if(this.comObj.link) {
        console.log(this.comObj.link)
        this.$router.push(this.comObj.link)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.topbanner {
  position: relative;
  padding: 0 10px;
  box-sizing: border-box;
  width: 375px;
  height: 130px;
  // overflow: hidden;
  // background: linear-gradient(-90deg, rgb(255, 128, 0), rgb(255, 174, 0));
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-bottom: 30px;
}
.swiper-img {
  width: 100%;
}
/* 波浪动画 */
.banwater {
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 100;
}
.banwater-c {
  position: relative;
}
.banwater-1,
.banwater-2 {
  position: absolute;
  width: 100%;
  height: 20px;
  top: 0;
}
.banwater-1 {
  background: url(http://p97zmsgyo.bkt.clouddn.com/water-1.svg);
  background-repeat: repeat-x;
  background-size: 600px;
  animation: wave-animation-1 3.5s infinite linear;
}
.banwater-2 {
  top: 5px;
  background: url(http://p97zmsgyo.bkt.clouddn.com/water-2.svg);
  background-repeat: repeat-x;
  background-size: 600px;
  animation: wave-animation-2 6s infinite linear;
}
@keyframes wave-animation-1 {
  0% {
    background-position: 0 top;
  }

  100% {
    background-position: 600px top;
  }
}

@keyframes wave-animation-2 {
  0% {
    background-position: 0 top;
  }

  100% {
    background-position: 600px top;
  }
}
</style>
